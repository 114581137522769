import Logo from "../assets/logo.png";
import DextoolImg from "../assets/DextoolImg.png";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="navbar">
      <div className="lg_screen">
        <div className="left">
          <ul>
            <li>
              <a
                className="link"
                href="https://twitter.com/suckiverse"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://discord.com/invite/HCcEV9GyCz"
                target="_blank"
                rel="noreferrer"
              >
                Discord
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://t.me/suckiverse"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
            </li>
          </ul>
        </div>

        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <div className="right">
          <ul>
            <li>
              <a
                className="link"
                href="https://www.dextools.io/app/ether/pair-explorer/0x642ee7e2d1e92c658147531b878f2f5868c91fdc"
                target="_blank"
                rel="noreferrer"
              >
                Dextools
              </a>
              <div className="img_below">
                <img src={DextoolImg} alt="DextoolImg" />
              </div>
            </li>

            <li>
              <a
                className="link"
                href="https://etherscan.io/token/0x9e11415df8071a73ca2ef5375c24fe83baea5fd7"
                target="_blank"
                rel="noreferrer"
              >
                $SUCK
              </a>
            </li>

            <li>
              <a
                className="link"
                href="https://milker.suckiverse.com"
                target="_blank"
                rel="noreferrer"
              >
                $MILKER
              </a>
            </li>

            <li>
              <a
                className="link"
                href="https://dildao.suckiverse.com"
                target="_blank"
                rel="noreferrer"
              >
                DILDAO
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="sm_screen">
        <div className="d-flex align-items-center">
          <img src={Logo} alt="logo" style={{ height: 150 }} />
        </div>
        {showSidebar ? (
          <div className="hamburger" onClick={() => setShowSidebar(false)}>
            <CloseIcon style={{ fill: "#ec166e", fontSize: "3rem" }} />
          </div>
        ) : (
          <div className="hamburger" onClick={() => setShowSidebar(true)}>
            <MenuIcon style={{ fill: "#ec166e", fontSize: "3rem" }} />
          </div>
        )}
      </div>

      {showSidebar && (
        <div className="sm_sidebar">
          <ul>
            <li>
              <a
                className="link"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
                onClick={() => setShowSidebar(false)}
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://discord.com/invite/HCcEV9GyCz"
                target="_blank"
                rel="noreferrer"
                onClick={() => setShowSidebar(false)}
              >
                Discord
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://t.me/suckiverse"
                target="_blank"
                rel="noreferrer"
                onClick={() => setShowSidebar(false)}
              >
                Telegram
              </a>
            </li>

            <li>
              <a
                className="link"
                href="https://www.dextools.io/app/ether/pair-explorer/0x642ee7e2d1e92c658147531b878f2f5868c91fdc"
                target="_blank"
                rel="noreferrer"
                onClick={() => setShowSidebar(false)}
              >
                Dextools
              </a>
            </li>

            <li>
              <a
                className="link"
                href="https://etherscan.io/token/0x9e11415df8071a73ca2ef5375c24fe83baea5fd7"
                target="_blank"
                rel="noreferrer"
                onClick={() => setShowSidebar(false)}
              >
                $SUCK
              </a>
            </li>
            <li>
              <a
                className="link"
                href="https://milker.suckiverse.com"
                target="_blank"
                rel="noreferrer"
              >
                $MILKER
              </a>
            </li>

            <li>
              <a
                className="link"
                href="https://dildao.suckiverse.com"
                target="_blank"
                rel="noreferrer"
              >
                DILDAO
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
